export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";

export const DELETE_NOTIFICATIONS_REQUEST = "DELETE_NOTIFICATIONS_REQUEST";
export const DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATIONS_ERROR = "DELETE_NOTIFICATIONS_ERROR";
export const FILTER_SECTOR = "FILTER_SECTOR";
export const COMMERCIAL_DBS = "COMMERCIALS_DBS";
export const PARTNERSHIPS_PROJECTS = "PARTNERSHIPS_PROJECTS";
export const FEMTECH_ANALYTICS_PROJECTS = "FEMTECH_ANALYTICS_PROJECTS";
export const ECOSYSTEM_PROJECTS = "ECOSYSTEM_PROJECTS";
export const GCC_PROJECTS = "GCC_PROJECTS";

import {
  GET_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_REQUEST,
  FILTER_SECTOR,
  COMMERCIAL_DBS,
  PARTNERSHIPS_PROJECTS,
  GCC_PROJECTS,
  FEMTECH_ANALYTICS_PROJECTS,
  ECOSYSTEM_PROJECTS
} from "./constants";

export const getNotificationsRequest = () => {
  return {
    type: GET_NOTIFICATIONS_REQUEST
  };
};

export const deleteNotificationsRequest = (data) => {
  return {
    type: DELETE_NOTIFICATIONS_REQUEST,
    data
  };
};

export const addSectorFilter = (data) => {
  return {
    type: FILTER_SECTOR,
    data
  };
};

export const setCommercialDbs = (data) => {
  return {
    type: COMMERCIAL_DBS,
    data
  };
};

export const setPartnershipsProjects = (data) => {
  return {
    type: PARTNERSHIPS_PROJECTS,
    data
  };
};

export const setFemtechAnalyticsProjects = (data) => {
  return {
    type: FEMTECH_ANALYTICS_PROJECTS,
    data
  };
};

export const setEcosystemProjects = (data) => {
  return {
    type: ECOSYSTEM_PROJECTS,
    data
  };
};

// GCC_PROJECTS
export const setGccProjects = (data) => {
  return {
    type: GCC_PROJECTS,
    data
  };
};
